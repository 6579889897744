import axios from "axios";
import jwtDecode from "jwt-decode";
import { WEB_GTWY_API_URL } from "src/config";
import {
  getFilterQueryUrl,
  setDefaultCurrency,
  toastNotification,
} from "./helper";
import {
  currentEarningValueResponse,
  currentInvestmentResponse,
  fetchALlAssetsResponse,
  fetchAvailableBalanceResponse,
  investmentHoldingResponse,
  investmentTransactionResponse,
  portfolioResponse,
} from "src/content/dashboards/Tos/Earn/staticData";

let reload_count = 0;
const getRequestHeaders = async ({ with_env, with_log } = {}) => {
  const header = { "Content-Type": "application/json" };
  const access_token = window.localStorage.getItem("access_token");
  if (access_token) {
    if (with_env) {
      const env = getEnvironment();
      header["x-env"] = env;
    }
    if (with_log) {
      header["x-is-relevant"] = true;
    }
    if (isValidToken(access_token)) {
      if (access_token) {
        header["Authorization"] = `Bearer ${access_token}`;
      }
    } else {
      const refresh_token_value = window.localStorage.getItem("refresh_token");
      if (refresh_token_value) {
        if (isValidToken(refresh_token_value)) {
          const new_access_token = await getNewAccessToken(refresh_token_value);

          header["Authorization"] = `Bearer ${new_access_token}`;
        } else {
          if (reload_count === 0) {
            toastNotification(
              "error",
              "Your session has expired, Please log in to continue",
              1500
            );
            setTimeout(() => {
              localStorage.clear();
              setDefaultCurrency();
              window.location.reload();
            }, 1500);
          }
          reload_count++;
        }
      }
    }
  }

  localStorage.setItem("last_active_at", new Date().toISOString());
  dispatchEvent(new Event("storage"));
  return header;
};

export const getNewAccessToken = async (refresh_token_value) => {
  const url = `${WEB_GTWY_API_URL}/refresh`;
  const requestHeaders = {
    "Content-Type": "application/json",
  };
  const response = await axios.post(
    url,
    {
      refresh_token: refresh_token_value,
    },
    {
      headers: requestHeaders,
    }
  );
  const { data } = response.data;
  const {
    access_token,
    refresh_token,
    access_token_expires_at,
    refresh_token_expires_at,
  } = data;
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("access_token_expires_at", access_token_expires_at);
  localStorage.setItem("refresh_token_expires_at", refresh_token_expires_at);
  dispatchEvent(new Event("storage"));
  return access_token;
};
const getEnvironment = () => {
  const mode = window.localStorage.getItem("apiMode") || "test";
  const env = mode === "live" ? "production" : "sandbox";
  return env;
};
const createRequest = async () => {
  const request = {};

  return request;
};
const isValidToken = (access_token) => {
  if (!access_token) {
    return false;
  }

  const decoded = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const getOrgAccDetails = async () => {
  const url = `${WEB_GTWY_API_URL}/organization-user`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  const allowed_env =
    response.headers["x-allowed-env"] || response.headers["X-Allowed-Env"];
  if (allowed_env !== "both") {
    const new_env = allowed_env === "production" ? "live" : "test";
    localStorage.setItem("apiMode", new_env);
  }
  return { ...response.data, data: { ...response.data.data, allowed_env } };
};
export const forgotPasswordEmail = async (email, recaptcha_token) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/reset-password`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.email = email;
  requestData["g-recaptcha-response"] = recaptcha_token;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updatePasswordWithCode = async (reset_code, new_password) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/reset-password`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.password_reset_code = reset_code;
  requestData.new_password = new_password;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateUserEmail = async (email) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/email`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.email = email;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const updateUserPhoneNumber = async (
  phone_country_code,
  phone_number
) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/phone-number`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.phone_country_code = phone_country_code;
  requestData.phone_number = phone_number;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const logoutSession = async (session_id) => {
  const url = `${WEB_GTWY_API_URL}/logout/${session_id}`;
  const response = await axios.delete(url);
  return response.data;
};

export const getOrganizationSetting = async (
  mode,
  key,
  filterQueryArr = []
) => {
  const environment =
    mode === "all" ? "all" : mode === "test" ? "sandbox" : "production";
  let url = `${WEB_GTWY_API_URL}/organization-setting/${key}`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = environment;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createOrganizationSetting = async (
  mode,
  data,
  with_log = false
) => {
  const environment =
    mode === "all" ? "all" : mode === "test" ? "sandbox" : "production";

  const url = `${WEB_GTWY_API_URL}/organization-setting`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = environment;
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };

  const response = await axios.put(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendVerifyEmail = async () => {
  const url = `${WEB_GTWY_API_URL}/organization-user/resend-verification-email`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const shopifyOnboarding = async (values) => {
  const url = `${WEB_GTWY_API_URL}/plugin-api-key/validate`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData["production"] = {
    access_key: values.access_key,
    secret_key: values.secret_key,
  };
  requestData["sandbox"] = {
    access_key: values.sandbox_access_key,
    secret_key: values.sandbox_secret_key,
  };
  requestData["plugin_type"] = "shopify";
  requestData["session_info"] = {
    shop_domain: values["shop"],
    state: values["state"],
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const resendLoginOtp = async (auth_id, session_id) => {
  const url = `${WEB_GTWY_API_URL}/resendLoginOtp`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.auth_id = auth_id;
  requestData.session_id = session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const sendOtpOnPhoneEmail = async (requestObj) => {
  const url = `${WEB_GTWY_API_URL}/sendOtpOnPhoneEmail`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.auth_id = requestObj.auth_id;
  requestData.session_id = requestObj.session_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const addAccount = async (userData) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/add`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  let requestData = await createRequest();
  requestData = { ...userData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateOrgUserPeer = async (org_user_id, userData) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/${org_user_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  let requestData = await createRequest();
  requestData.is_admin = userData.is_admin;
  requestData.is_developer = userData.is_developer;
  requestData.sub_role = userData.sub_role;

  if (userData.hasOwnProperty("is_active")) {
    requestData.is_active = userData.is_active;
  }
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchOrgUserPeerById = async (org_user_id) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/peers/${org_user_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const sendMobileOtp = async (phone_country_code, phone_number) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/send-phonenumber-verification-otp`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.phone_country_code = phone_country_code;
  requestData.phone_number = phone_number;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyMobileOtp = async (otp) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/verify-phonenumber-verification-otp`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.otp = otp;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getKybOrgDetails = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/organization`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrganizationDetail = async (value) => {
  const url = `${WEB_GTWY_API_URL}/organization`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.organization = value;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllOrganizationAccounts = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/organization-user/peers`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isValidAcnAbn = async (is_abn_acn, abn_acn_value) => {
  let url = "";
  if (is_abn_acn === "abn") {
    url = `${WEB_GTWY_API_URL}/organization/is-valid-abn/${abn_acn_value}`;
  } else if (is_abn_acn === "acn") {
    url = `${WEB_GTWY_API_URL}/organization/is-valid-acn/${abn_acn_value}`;
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isValidBsb = async (bsb_number) => {
  const url = `${WEB_GTWY_API_URL}/organization/is-valid-bsb/${bsb_number}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const isValidPayId = async (pay_id) => {
  const url = `${WEB_GTWY_API_URL}/organization/is-valid-payid/${pay_id}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadContract = async (
  org_id,
  org_document_id,
  signature_id,
  doc_type = ""
) => {
  const url = `${WEB_GTWY_API_URL}/organization-document/${org_document_id}/sign-document/${signature_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-org-id"] = org_id;
  const requestData = await createRequest();
  if (doc_type) {
    requestData.doc_type = doc_type;
  }
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const mergeContractDocument = async (org_id) => {
  const url = `${WEB_GTWY_API_URL}/organization/merge-contract-documents`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(
    url,
    {},
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const uploadDoc = async (file, org_id, org_document_id) => {
  const response = await uploadOrgDocs(file, "Signature");
  const signature_id = response.org_document_id;
  await uploadContract(org_id, org_document_id, signature_id);
  return response.status;
};

export const uploadMpaSignDoc = async (file, org_id, org_document_id) => {
  const response = await uploadOrgDocs(file, "Signature");
  const signature_id = response.org_document_id;
  await uploadContract(org_id, org_document_id, signature_id, "agreement");
  return response.status;
};

export const updatePermissionsMpa = async (data) => {
  const url = `${WEB_GTWY_API_URL}/organization/affix-permissions`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  requestHeaders["x-org-id"] = data.org_id;
  let requestData = await createRequest();
  requestData.partner_permission_list = data.permissions;
  requestData.partner_id = data.partner_id;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const uploadOrgDocs = async (file, doc_descriptor, file_type) => {
  const url = `${WEB_GTWY_API_URL}/organization-document`;
  const requestHeaders = await getRequestHeaders();
  const requestData = await createRequest();
  requestData.file_name = file.name;
  requestData.file_type = file_type ? file_type : file.type;
  requestData.descriptor = doc_descriptor;

  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  const uploadUrl = response.data.data.upload_url;
  const org_document_id = response.data.data.org_document_id;
  delete axios.defaults.headers.common.Authorization;
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
    redirect: "follow",
  });
  return { status: uploadResponse.status === 200, org_document_id };
};

export const createKybOrgDetails = async (kybData) => {
  const url = `${WEB_GTWY_API_URL}/organization/kyb/submit`;
  const requestHeaders = await getRequestHeaders();

  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const preSubmitKybOrgDetails = async (kybData) => {
  const url = `${WEB_GTWY_API_URL}/organization/kyb/pre-submit`;
  const requestHeaders = await getRequestHeaders();

  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteShareHolder = async (org_shareholder_id) => {
  const url = `${WEB_GTWY_API_URL}/organization-shareholder/${org_shareholder_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteDirector = async (org_director_id) => {
  const url = `${WEB_GTWY_API_URL}/organization-director/${org_director_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const saveKybOrgDetails = async (kybData) => {
  const url = `${WEB_GTWY_API_URL}/organization/kyb/save-and-continue`;
  const requestHeaders = await getRequestHeaders();

  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateKybOrgDetails = async (kybData) => {
  const url = `${WEB_GTWY_API_URL}/organization/kyb/submit`;
  const requestHeaders = await getRequestHeaders();

  const response = await axios.patch(url, kybData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createApiKey = async (environment) => {
  const url = `${WEB_GTWY_API_URL}/api-key`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const requestData = await createRequest();
  // requestData.environment = environment === "test" ? "sandbox" : "production";
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllApiKeys = async (mode, with_log = false, filterQueryArr) => {
  const env = mode === "test" ? "sandbox" : "production";
  let url = `${WEB_GTWY_API_URL}/api-key`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayments = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });

  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchPaymentById = async (payment_id, mode) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/payin/${payment_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchPayinPdfDownloadUrl = async (payin_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin/${payin_id}/get-payin-pdf`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllDisputes = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/dispute`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchDisputeById = async (dispute_id, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/dispute/${dispute_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllPayouts = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchPayoutById = async (payoutId, mode, with_log = false) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/payout/${payoutId}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchPayoutPdfDownloadUrl = async (payoutId) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout/get-payout-pdf/${payoutId}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createNewPayout = async (payoutData, mfa_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  requestHeaders["x-mfa-id"] = mfa_id;
  let requestData = await createRequest();
  requestData = { ...payoutData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createNewBulkPayout = async (data = {}) => {
  const url = `${WEB_GTWY_API_URL}/torc/bulk-payout`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...requestData, ...data };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllBulkPayouts = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/bulk-payout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBulkPayoutByID = async (id) => {
  const url = `${WEB_GTWY_API_URL}/torc/bulk-payout/${id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllPayoutsByBulkPayoutID = async (
  bulk_payout_id,
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/bulk-payout/${bulk_payout_id}/payout_list`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllSenders = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-sender`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchSenderById = async (senderId, mode, with_log = false) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/payout-sender/${senderId}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const createNewSender = async (senderData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-sender`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...senderData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateSender = async (sender_id, senderData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-sender/${sender_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...senderData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllBeneficiary = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchBeneficiaryById = async (
  beneficiaryId,
  mode,
  with_log = false
) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary/${beneficiaryId}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const createNewBeneficiary = async (beneficiaryData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...beneficiaryData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updateBeneficiary = async (beneficiary_id, beneficiaryData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary/${beneficiary_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...beneficiaryData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};
export const updatePayoutCheckerStatus = async (payout_id, action) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout/${payout_id}/checker`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { action };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateBeneficiary = async (body) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-beneficiary/validate`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.post(url, body, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllTransactions = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllInvoiceTransactions = async (
  invoice_id,
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/transaction-report`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllWalletTransactions = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction`;

  // This `is_relevant` filter is used prevent fetching and showing those RESERVED wallet-txns which have already been RELEASED.
  filterQueryArr.push({ filter_key: "is_relevant", filter_value: "1" });
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWalletTransactionById = async (
  transaction_id,
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-transaction/${transaction_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllWFT = async (filterQueryArr, with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWFTById = async (wft_id, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer/${wft_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllWebhookNotification = async (
  filterQueryArr,
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/webhook-notification`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWebhookNotificationById = async (
  webhook_id,
  mode,
  with_log = false
) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/webhook-notification/${webhook_id}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const RetriggerWebhookNotificationById = async (webhook_id, mode) => {
  const url = `${WEB_GTWY_API_URL}/torc/webhook-notification/retrigger/${webhook_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  delete requestHeaders["Content-Type"];
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllCheckouts = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/checkout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchCheckoutById = async (
  checkout_id,
  mode,
  with_log = false
) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/checkout/${checkout_id}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createNewWallet = async (walletData) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...walletData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllWallets = async (filterQueryArr = [], mode) => {
  const env = mode === "test" ? "sandbox" : "production";
  let url = `${WEB_GTWY_API_URL}/torc/wallet`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchCustomerWallets = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/wallet?scope=CUSTOMER`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}&${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const fetchWalletById = async (wallet_id, mode, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet/${wallet_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWalletBalanceByIdCur = async (wallet_id, mode) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet/balance/${wallet_id}/currency/AUD`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchWalletsByCustomerId = async (customer_id, mode) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/wallet?customer_id=${customer_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllCustomers = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/customer`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};
export const fetchCustomerById = async (customer_id, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};
export const isWalletReady = async (customer_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}/is-wallet-ready`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createNewCustomer = async (customerData) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...customerData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteCustomer = async (customerId) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customerId}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const customerEnableDisable = async (customer_id, type) => {
  let url;
  if (type === "disable") {
    url = `${WEB_GTWY_API_URL}/torc/customer/enable/${customer_id}`;
  } else {
    url = `${WEB_GTWY_API_URL}/torc/customer/disable/${customer_id}`;
  }
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchWalletMaster = async (
  mode,
  category = "",
  scope = "MASTER",
  customer_id = ""
) => {
  const env = mode === "test" ? "sandbox" : "production";
  let url = `${WEB_GTWY_API_URL}/torc/wallet?scope=${scope}`;
  if (!!customer_id) {
    url = `${WEB_GTWY_API_URL}/torc/wallet?scope=${scope}&category=${category}&customer_id=${customer_id}`;
  } else if (category) {
    url = `${WEB_GTWY_API_URL}/torc/wallet?scope=${scope}&category=${category}`;
  }
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const deleteApiKey = async (api_key_id) => {
  const url = `${WEB_GTWY_API_URL}/api-key/${api_key_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const enableApiKey = async (api_key_id) => {
  const url = `${WEB_GTWY_API_URL}/api-key/enable/${api_key_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateCustomerGcaStatus = async (customer_id, data, org_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}/manage-gca`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  if (org_id) {
    requestHeaders["x-org-id"] = org_id;
  }
  let requestData = await createRequest();
  requestData = { ...data, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateCustomer = async (customer_id, customerData) => {
  const url = `${WEB_GTWY_API_URL}/torc/customer/${customer_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...customerData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const disableApiKey = async (api_key_id) => {
  const url = `${WEB_GTWY_API_URL}/api-key/disable/${api_key_id}`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createNewCheckout = async (checkoutData, mode) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/checkout`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  requestHeaders["x-env"] = env;
  let requestData = await createRequest();
  requestData = { ...checkoutData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const cancelCheckout = async (checkoutID) => {
  const url = `${WEB_GTWY_API_URL}/torc/checkout/${checkoutID}/cancel`;
  let requestData = await createRequest();
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchKybDocUrl = async (org_document_id, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/organization-document/${org_document_id}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateUserPassword = async (userData) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/change-password`;
  const requestHeaders = await getRequestHeaders({ with_log: true });
  const requestData = await createRequest();
  requestData.old_password = userData.old_password;
  requestData.new_password = userData.new_password;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyPasswordResetCode = async (reset_code) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/reset-password/${reset_code}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateUserProfile = async (userData, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/organization-user`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const setTotpSecret = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/set-totp-secret`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateTotpStatus = async (userData, with_log = true) => {
  const url = `${WEB_GTWY_API_URL}/update-totp-status`;
  const requestHeaders = await getRequestHeaders({ with_log });
  let requestData = await createRequest();
  requestData = { ...requestData, ...userData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPaymentMethod = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin-method/all`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders({ with_env: true });
  // requestHeaders["x-env"] = "production";
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchEnabledPaymentMethod = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin-method/all/enabled`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders({ with_env: true });
  // requestHeaders["x-env"] = "production";
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllPayoutMethod = async (
  filterQueryArr = [
    {
      filter_key: "sender_country",
      filter_value: "AU",
    },
    {
      filter_key: "sender_currency",
      filter_value: "AUD",
    },
    {
      filter_key: "beneficiary_country",
      filter_value: "AU",
    },
    {
      filter_key: "beneficiary_currency",
      filter_value: "AUD",
    },
  ]
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payout-method/all`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};
export const getPayoutMethodParamsByName = async (payout_method_name) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method/param/${payout_method_name}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createNewPayinSender = async (payinData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin-sender`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...payinData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllPayinSender = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin-sender`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchPayinSenderById = async (
  senderID,
  mode,
  with_log = false
) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/torc/payin-sender/${senderID}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updatePayinSender = async (sender_id, payinSenderData) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin-sender/${sender_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...payinSenderData, ...requestData };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};


export const getPayoutMethodParamsById = async (payout_method_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method/${payout_method_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingById = async (pricingId) => {
  const url = `${WEB_GTWY_API_URL}/pricing/${pricingId}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  // requestHeaders['x-env'] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchOrgPricing = async (with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/pricing/organization`;
  const requestHeaders = await getRequestHeaders({ with_log });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingByPaymentMethodId = async (
  payin_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/pricing/organization/payin-method/${payin_method_id}/payin-method-class/${pm_class}/payin-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPricingByPayoutMethodId = async (
  payout_method_id,
  pm_class,
  pm_group
) => {
  const url = `${WEB_GTWY_API_URL}/pricing/organization/payout-method/${payout_method_id}/payout-method-class/${pm_class}/payout-method-group/${pm_group}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchAllVirtualAccounts = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/virtual-account`;

  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }

  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });

  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};
export const fetchVirtualAccountById = async (
  virtualAccountId,
  with_log = false
) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account/${virtualAccountId}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const deleteVirtualAccount = async (vaId) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account/${vaId}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.delete(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateVirtualAccount = async (vaId, credit_wallet_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account/${vaId}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const requestData = await createRequest();
  requestData.credit_wallet_id = credit_wallet_id;

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchVAByPayinWalletID = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/virtual-account`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getPayinMethodParamDetail = async (payment_method_name, mode) => {
  let url = `${WEB_GTWY_API_URL}/torc/payin-method/param/${payment_method_name}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createNewVirtualAccount = async (checkoutData) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...checkoutData, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const SimulatePayin = async (
  simulateOption,
  payId,
  payin_amount,
  payin_currency,
  mode
) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin/simulate`;
  const env = mode === "test" ? "sandbox" : "production";
  const requestHeaders = await getRequestHeaders({ with_log: true });
  requestHeaders["x-env"] = env;
  let requestData = await createRequest();
  requestData = {
    ...requestData,
    payin_id: payId,
    status: simulateOption,
    amount: Number(payin_amount),
    currency: payin_currency,
  };

  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const cancelPayin = async (payID) => {
  const url = `${WEB_GTWY_API_URL}/torc/payin/${payID}/cancel`;
  let requestData = await createRequest();
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const SimulatePayout = async (
  payoutId,
  status,
  beneficiary_amount,
  beneficiary_currency
) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout/simulate`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = {
    ...requestData,
    payout_id: payoutId,
    status,
    beneficiary_amount,
    beneficiary_currency,
  };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const NewRefund = async (
  payId,
  refund_type,
  payin_amount,
  payin_currency,
  org_ref_id,
  description,
  mode
) => {
  const url = `${WEB_GTWY_API_URL}/torc/refund`;
  const env = mode === "test" ? "sandbox" : "production";
  const requestHeaders = await getRequestHeaders({ with_log: true });
  requestHeaders["x-env"] = env;
  let requestData = await createRequest();
  requestData = {
    ...requestData,
    payin_id: payId,
    type: refund_type,
    amount: Number(payin_amount),
    currency: payin_currency,
    organization_reference_id: org_ref_id || null,
    description: description,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const SimulateRefund = async (refund_id, status) => {
  const url = `${WEB_GTWY_API_URL}/torc/refund/simulate`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = {
    ...requestData,
    refund_id: refund_id,
    status: status,
  };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyEmailToken = async (email_token) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/verify-email/${email_token}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const verifyEmailAddress = async (email_token, password) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/verify-email`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData.email_verification_token = email_token;
  requestData.password = password;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyInviteToken = async (token) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/validate-invitation-token/${token}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const verifyKybApprovalToken = async (token, status) => {
  const url = `${WEB_GTWY_API_URL}/organization/merchant-kyb-permission/${status}`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.patch(
    url,
    {
      token,
    },
    {
      headers: requestHeaders,
    }
  );

  return response.data;
};

export const resetInvitePassword = async (token, password) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/reset-invitation-password`;
  const requestHeaders = await getRequestHeaders();
  let requestData = await createRequest();
  requestData.invitation_token = token;
  requestData.password = password;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllRefund = async (filterQueryArr = [], with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/refund`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getRefundById = async (refund_id, with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/refund/${refund_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllSettlement = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getSettlement = async (settle_id, with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement/${settle_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementPayout = async (filterQueryArr, with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/settlement-payout`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchSettlePayoutById = async (payoutId, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/settlement-payout/${payoutId}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const downloadSettlePayout = async (payoutId, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/settlement-payout/${payoutId}/pdf`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const updateOrgSettlementBank = async (stlmnt_bnk_id, updated_data) => {
  let url = `${WEB_GTWY_API_URL}/organization-settlement-bank/${stlmnt_bnk_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...requestData, ...updated_data };
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getTransactionId = async (transaction_id, with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/torc/transaction/${transaction_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const fetchAllInvoice = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};
export const getInvoiceById = async (invoice_id, with_log = false) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const invoiceAlreadyPaid = async (obj) => {
  const url = `${WEB_GTWY_API_URL}/torc/invoice/is-paid`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const requestData = await createRequest();
  requestData.invoice_id = obj.invoice_id;
  requestData.amount = obj.amount;
  requestData.fees_currency = obj.fees_currency;
  requestData.event_id = obj.event_id || null;
  requestData.payment_mode = obj.payment_mode;
  requestData.additional_data = obj.additional_data;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllCreditFeesTrxns = async (
  invoice_id,
  payment_mode = "VA_PAYIN"
) => {
  let url = `${WEB_GTWY_API_URL}/torc/invoice/${invoice_id}/transaction/${payment_mode}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getAllOrgControl = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/organization-control`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgControlByKeyTypeAndKey = async (key_type, key, env) => {
  const url = `${WEB_GTWY_API_URL}/organization-control/${key_type}/${key}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  if (env) {
    requestHeaders["x-env"] = env;
  }
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const simulateVirtualAccPayin = async (amount, vaId) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account/simulate-payin`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData.amount = +amount;
  requestData.virtual_account_id = vaId;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getCountryState = async (country_name) => {
  const url = `https://countriesnow.space/api/v0.1/countries/states`;
  let requestData = await createRequest();
  requestData = {
    country: country_name,
  };
  const response = await axios.post(url, requestData);
  return response.data;
};

export const getCountryStateCity = async (country_name, state_name) => {
  const url = `https://countriesnow.space/api/v0.1/countries/state/cities`;
  let requestData = await createRequest();
  requestData = {
    country: country_name,
    state: state_name,
  };
  const response = await axios.post(url, requestData);
  return response.data;
};

export const resendUserInvitation = async (org_user_id) => {
  const url = `${WEB_GTWY_API_URL}/organization-user/resend-user-invitation-email/${org_user_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const walletFundTranfer = async (payload) => {
  const url = `${WEB_GTWY_API_URL}/torc/wallet-fund-transfer`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = {
    debit_wallet_id: payload.debit_wallet_id,
    credit_wallet_id: payload.credit_wallet_id,
    amount: +payload.amount,
    currency: payload.currency,
    organization_reference_id: payload.organization_reference_id
      ? payload.organization_reference_id
      : null,
    description: payload.description ? payload.description : null,
  };
  if (payload?.invoice_id) {
    requestData.invoice_id = payload.invoice_id;
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getOrgSettlementBank = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/organization-settlement-bank`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getOrgSettlementBankById = async (
  org_settlement_bank_id,
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/organization-settlement-bank/${org_settlement_bank_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createOrgSettlementBank = async (bankData) => {
  const url = `${WEB_GTWY_API_URL}/organization-settlement-bank`;
  const requestHeaders = await getRequestHeaders({ with_log: true });

  const response = await axios.post(url, bankData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getSettlementOrgConfig = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/settlement-organization-config`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const updateSettlementOrgConfig = async (config_id, values) => {
  const url = `${WEB_GTWY_API_URL}/settlement-organization-config/${config_id}`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData.is_settlement_enabled = values.is_settlement_enabled;
  requestData.settlement_payout_mode = values.settlement_payout_mode;
  requestData.settlement_schedule = values.settlement_schedule;
  requestData.settlement_schedule_custom_date =
    values.settlement_schedule_custom_date || null;
  requestData.settlement_payout_reference =
    values.settlement_payout_reference || null;
  requestData.org_settlement_bank_id = values.org_settlement_bank_id || null;
  requestData.min_amount_threshold = +values.min_amount_threshold;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getGCASourceTargetCurrency = async () => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-gca/currency/source-target-currency`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getAllGCACurrency = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-gca/currency`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getAllGCACurrencyByConnector = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-gca/currency/all-connector`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const addGCACurrency = async (
  currency_list,
  selected_customer_id = ""
) => {
  const url = `${WEB_GTWY_API_URL}/torc/fx-gca/currency`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData.currency = currency_list;
  if (!!selected_customer_id) {
    requestData["customer_id"] = selected_customer_id;
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getNewFxRate = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/fx-rate`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  let requestData = await createRequest();
  requestData.source_amount = +data.source_amount || null;
  requestData.source_currency = data.source_currency || null;
  requestData.target_amount = +data.target_amount || null;
  requestData.target_currency = data.target_currency || null;
  if (data.customer_id) {
    requestData["customer_id"] = data.customer_id;
  }
  if (data.settle_by) {
    requestData.settle_by = data.settle_by;
  }
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getFxRateById = async (fx_rate_id) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-rate/${fx_rate_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getAllGCAConversion = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-conversion`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getGCAConversionById = async (
  fx_conversion_id,
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-conversion/${fx_conversion_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const simulateFxConversion = async (fx_conversion_id, status) => {
  const url = `${WEB_GTWY_API_URL}/torc/fx-conversion/simulate`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData.fx_conversion_id = fx_conversion_id;
  requestData.status = status;
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createNewFxConversion = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/fx-conversion`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = { ...data, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getFxSettlementTime = async (payout_method_name) => {
  const url = `${WEB_GTWY_API_URL}/torc/payout-method/${payout_method_name}/cutoff-and-settlement-time`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getFxSettlementFees = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/misc/fees`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateSettlementBankWithData = async (
  payout_method_name,
  data
) => {
  const url = `${WEB_GTWY_API_URL}/organization-settlement-bank/payout-method/${payout_method_name}/validate-settlement-bank`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  let requestData = await createRequest();
  requestData = { ...data, ...requestData };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const validateSettlementBankWithId = async (
  payout_method_name,
  org_settlement_bank_id
) => {
  const url = `${WEB_GTWY_API_URL}/organization-settlement-bank/${org_settlement_bank_id}/payout-method/${payout_method_name}/validate-settlement-bank`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAnalyticsCurrencyList = async (filterArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/org-analytics/currency-list`;
  if (filterArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const fetchPayinAnalytics = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/torc/org-analytics`;
  if (filterQueryArr.length > 0) {
    const filterUrl = await getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const populateMT103 = async (data) => {
  let url = `${WEB_GTWY_API_URL}/torc/async-connector-action/populate-mt-103`;
  const requestData = await createRequest();
  requestData.payout_id = data.payout_id;
  requestData.org_id = data.org_id;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPaymentMethodSettingById = async (payment_method_id) => {
  const url = `${WEB_GTWY_API_URL}/organization-payment-method-setting/${payment_method_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getAllPaymentMethodSettings = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/organization-payment-method-setting`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const enablePaymentMethodSetting = async (
  payment_method_id,
  payment_method_type
) => {
  const url = `${WEB_GTWY_API_URL}/organization-payment-method-setting/${payment_method_id}/enable`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(
    url,
    { payment_method_type },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const disablePaymentMethodSetting = async (
  payment_method_id,
  payment_method_type
) => {
  const url = `${WEB_GTWY_API_URL}/organization-payment-method-setting/${payment_method_id}/disable`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(
    url,
    { payment_method_type },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const requestActivationForPMS = async (
  payment_method_id,
  payment_method_type
) => {
  const url = `${WEB_GTWY_API_URL}/organization-payment-method-setting`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  let requestData = await createRequest();
  requestData = {
    payment_method_id,
    payment_method_type,
  };
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getBasicFxRates = async (currency_pair_list) => {
  let url = `${WEB_GTWY_API_URL}/torc/list-fx-rates`;
  const requestData = await createRequest();
  requestData.currency_pair_list = currency_pair_list;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  // requestHeaders["x-organization-id"] = "org_a8e21101f9494811b51321f5334c3c84";
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAllAdjustments = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/torc/adjustment`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getAdjustmentById = async (adjust_id, with_log = false) => {
  const URL = `${WEB_GTWY_API_URL}/torc/adjustment/${adjust_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const fetchAllAudits = async (filterQueryArr = []) => {
  let url = `${WEB_GTWY_API_URL}/audit-trail`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getAuditTrailById = async (audit_id) => {
  const URL = `${WEB_GTWY_API_URL}/audit-trail/${audit_id}`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(URL, { headers: requestHeaders });
  return response.data;
};

export const createNewInboundFundNotice = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/inbound-fund-notice`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  let requestData = await createRequest();
  requestData.credit_wallet_id = data.credit_wallet_id;
  requestData.source_amount = +data.source_amount;
  requestData.source_currency = data.source_currency;
  requestData.target_currency = data.target_currency;
  requestData.pod_org_doc_id = data.pod_org_doc_id;
  requestData.anticipated_amount = +data.anticipated_amount || null;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchAllInboundFundNotice = async (filterQueryArr) => {
  let url = `${WEB_GTWY_API_URL}/torc/inbound-fund-notice`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchTopUpBankDetails = async (filterQueryArr) => {
  let url = `${WEB_GTWY_API_URL}/torc/topup/bank-account`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getIFNFxRates = async (currency_pair_list) => {
  let url = `${WEB_GTWY_API_URL}/torc/ifn-fx-rates`;
  const requestData = await createRequest();
  requestData.currency_pair_list = currency_pair_list;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const simulateInboundFundNotice = async (data) => {
  const url = `${WEB_GTWY_API_URL}/torc/inbound-fund-notice/simulate`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  let requestData = await createRequest();
  requestData.credit_wallet_id = data.credit_wallet_id;
  requestData.source_amount = +data.source_amount;
  requestData.source_currency = data.source_currency;
  requestData.target_currency = data.target_currency;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

// export const createAuditTrail = async (data) => {
//   const URL = `${WEB_GTWY_API_URL}/audit-trail`;
//   const requestHeaders = await getRequestHeaders({ with_env: true });
//   const requestData = await createRequest();
//   requestData.http_method = data.http_method;
//   requestData.http_status_code = data.http_status_code;
//   requestData.http_request_url = data.http_request_url;
//   requestData.event_name = data.event_name;
//   requestData.event_metadata = data.event_metadata;
//   requestData.source = data.source;
//   requestData.created_at = new Date(new Date().toUTCString());

//   const response = await axios.get(URL, requestData, {
//     headers: requestHeaders,
//   });
//   return response.data;
// };

export const fetchAllBalances = async () => {
  const url = `${WEB_GTWY_API_URL}/torc/balance?detailed=true`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPartnerSetting = async (key, partner_id) => {
  let url = `${WEB_GTWY_API_URL}/partner/partner-setting/${key}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-id"] = partner_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const getPartnerDocument = async (partner_id, partner_document_id) => {
  let url = `${WEB_GTWY_API_URL}/partner/partner-document/${partner_document_id}`;
  const requestHeaders = await getRequestHeaders();
  requestHeaders["x-ptnr-id"] = partner_id;
  const response = await axios.get(url, { headers: requestHeaders });
  return response.data;
};

export const createReport = async (
  filterQueryArr = [],
  report_type,
  file_type = "csv"
) => {
  let url = `${WEB_GTWY_API_URL}/report`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.post(
    url,
    { report_type, file_type },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const getNonTradingDates = async (filterQueryArr) => {
  let url = `${WEB_GTWY_API_URL}/torc/fx-rate/non_trading_date`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getReport = async (filterQueryArr = [], with_log = false) => {
  let url = `${WEB_GTWY_API_URL}/report`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchReportById = async (report_id, mode, with_log = false) => {
  const env = mode === "test" ? "sandbox" : "production";
  const url = `${WEB_GTWY_API_URL}/report/${report_id}`;
  const requestHeaders = await getRequestHeaders({ with_log });
  requestHeaders["x-env"] = env;
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const getPluginApiKey = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/plugin-api-key`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({ with_env: true, with_log });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const createPluginApiKey = async (data) => {
  let url = `${WEB_GTWY_API_URL}/plugin-api-key`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.post(
    url,
    { plugin_type: data.plugin_type },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const togglePluginApiKeyStatus = async (plugin_api_key_id, action) => {
  let url = `${WEB_GTWY_API_URL}/plugin-api-key/${plugin_api_key_id}/toggle`;
  let is_active = false;
  if (action === "enable") {
    is_active = true;
  }

  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(
    url,
    {
      is_active,
    },
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const generateSignSecretKey = async () => {
  const url = `${WEB_GTWY_API_URL}/signature-key`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const requestData = await createRequest();
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const fetchSignSecretKey = async (
  filterQueryArr = [],
  with_log = false
) => {
  let url = `${WEB_GTWY_API_URL}/signature-key`;
  if (filterQueryArr.length > 0) {
    const filterUrl = getFilterQueryUrl(filterQueryArr);
    if (filterUrl) {
      url = `${url}?${filterUrl}`;
    }
  }
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log,
  });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });
  return response.data;
};

export const activateSignSecretKey = async (signature_key_id) => {
  const url = `${WEB_GTWY_API_URL}/signature-key/${signature_key_id}/activate`;
  const requestHeaders = await getRequestHeaders({
    with_env: true,
    with_log: true,
  });
  const response = await axios.patch(
    url,
    {},
    {
      headers: requestHeaders,
    }
  );
  return response.data;
};

export const getPartnerInfo = async () => {
  const url = `${WEB_GTWY_API_URL}/partner/info`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const InvestedAmount = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-holding/organization/current-investment`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return currentInvestmentResponse.data;
};

export const fetchAvailableBalance = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-holding/organization/fetch-available-balance`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return fetchAvailableBalanceResponse;
};

export const fetchPortfolioBalance = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-holding/organization/portfolio`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return portfolioResponse;
};

export const fetchEarningToDate = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-holding/organization/current-earning-value`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return currentEarningValueResponse;
};

export const fetchAllHoldingTransactions = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-transaction`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return investmentTransactionResponse;
};

export const fetchAllHoldings = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-holding`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return investmentHoldingResponse;
};

export const fetchAllAssets = async () => {
  let url = `${WEB_GTWY_API_URL}/earn/investment-assets`;
  const requestHeaders = await getRequestHeaders();
  // const response = await axios.get(url, { headers: requestHeaders });
  return fetchALlAssetsResponse;
};

export const confirmBuyAsset = async (values) => {
  const url = `${WEB_GTWY_API_URL}/earn/investment-holding`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

export const confirmSellAsset = async (values) => {
  const url = `${WEB_GTWY_API_URL}/earn/investment-holding/sell`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, values, {
    headers: requestHeaders,
  });
  return response.data;
};

//tos fx trade start
export const getFxTradeAll = async () => {
  const url = `${WEB_GTWY_API_URL}/tos/fx-trade`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(url, requestHeaders);
  return response;
};
export const createAction = async () => {
  const url = `${WEB_GTWY_API_URL}/tos/action`;
  const requestHeaders = await getRequestHeaders();
  const response = await axios.post(url, requestHeaders);
  return response;
};
//tos fx trade end

export const generateBankConfirmationPdf = async (virtual_account_id) => {
  const url = `${WEB_GTWY_API_URL}/torc/virtual-account/${virtual_account_id}/bank-account-confirmation-pdf`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.get(url, {
    headers: requestHeaders,
  });

  return response.data;
};

export const createMfa = async (data, org_user_id, org_id) => {
  const requestData = { metadata: { ...data }, event_type: "PAYOUT" };
  const url = `${WEB_GTWY_API_URL}/mfa/create-mfa`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  requestHeaders["x-org-user-id"] = org_user_id;
  requestHeaders["x-org-id"] = org_id;
  const response = await axios.post(url, requestData, {
    headers: requestHeaders,
  });
  return response.data;
};

export const verifyMfa = async (mfa_id, otp) => {
  const requestData = { mfa_id: mfa_id, otp: otp };
  const url = `${WEB_GTWY_API_URL}/mfa/${mfa_id}/verify`;
  const requestHeaders = await getRequestHeaders({ with_env: true });
  const response = await axios.patch(url, requestData, {
    headers: requestHeaders,
  });

  return response.data;
};

export const resendMfa = async (mfa_id) => {
  const url = `${WEB_GTWY_API_URL}/mfa/${mfa_id}/resend`;
  const requestHeaders = await getRequestHeaders({ with_env: true });

  const response = await axios.patch(url, {}, { headers: requestHeaders });

  return response.data;
};
