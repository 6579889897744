export const investmentHoldingResponse = {
  request_id: "5589d6932e6a9efecbd0ffc2296c1fd7",
  request_time: "2024-07-18T06:16:24.092Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: [
    {
      holding_id: "holding_id_33b8c3a0f8814a9ab2621292c338d317",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_b6535869a2214ac28be5e8ee49877e7e",
      currency: "USD",
      units: 2,
      cost_price: 20260,
      created_at: "2024-07-10T06:42:28.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:16:37.000Z",
      is_deleted: false,
      asset_name: "Endowus",
      asset_trading_price: 108.33,
      asset_min_size: 100,
      asset_yield: 7,
    },
    {
      holding_id: "holding_id_d17a96ede5ba4a17b82210a3cabe2fad",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_f2bfa91e4db748dc911257fc723c1fb6",
      currency: "USD",
      units: 1,
      cost_price: 1000,
      created_at: "2024-07-10T06:42:20.000Z",
      deleted_at: null,
      updated_at: "2024-07-10T06:42:20.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 104.25,
      asset_min_size: 100,
      asset_yield: 41,
    },
  ],
  _metadata: {
    page: 1,
    page_count: 1,
    total_count: 4,
    current_page_count: 4,
    max_per_page: 10,
    links: {
      self: "/investment-holding?page=1&limit=10",
      next: null,
      previous: null,
      first: "/investment-holding?page=1&limit=10",
      last: "/investment-holding?page=1&limit=10",
    },
  },
};

export const investmentTransactionResponse = {
  request_id: "36ae58f1c0af1708729dda6bdffcf652",
  request_time: "2024-07-18T06:16:24.085Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: [
    {
      transaction_id: "transaction_id_86c529877a57422eb2090d2c68411058",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      currency: "USD",
      units: 0,
      price_per_unit: 86.41,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 100,
      created_at: "2024-07-17T20:16:56.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:16:56.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 87.45,
    },
    {
      transaction_id: "transaction_id_691945d2826941db808d547e833da450",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_b6535869a2214ac28be5e8ee49877e7e",
      currency: "USD",
      units: 0,
      price_per_unit: 104.22,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 111,
      created_at: "2024-07-17T20:16:37.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:16:37.000Z",
      is_deleted: false,
      asset_name: "Endowus",
      asset_trading_price: 108.33,
    },
    {
      transaction_id: "transaction_id_967d714f02c04130986406a3043ced78",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      currency: "USD",
      units: 0,
      price_per_unit: 87.52,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 102,
      created_at: "2024-07-17T20:15:38.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:15:38.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 87.45,
    },
    {
      transaction_id: "transaction_id_af19e25f38df41a288acc4490fc1151a",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      currency: "USD",
      units: 0,
      price_per_unit: 83,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 100,
      created_at: "2024-07-17T20:13:41.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:13:41.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 87.45,
    },
    {
      transaction_id: "transaction_id_9a75a09ce13e420e96c75c3b0c0b1d2a",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_b6535869a2214ac28be5e8ee49877e7e",
      currency: "USD",
      units: 0,
      price_per_unit: 104.22,
      status: "COMPLETED",
      type: "SELL",
      total_amount: 1,
      created_at: "2024-07-17T12:47:34.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T12:47:34.000Z",
      is_deleted: false,
      asset_name: "Endowus",
      asset_trading_price: 108.33,
    },
    {
      transaction_id: "transaction_id_50b8ebd306d648068e4d51de32bac6bb",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_b6535869a2214ac28be5e8ee49877e7e",
      currency: "USD",
      units: 0,
      price_per_unit: 104.22,
      status: "COMPLETED",
      type: "SELL",
      total_amount: 1,
      created_at: "2024-07-17T12:47:21.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T12:47:21.000Z",
      is_deleted: false,
      asset_name: "Endowus",
      asset_trading_price: 108.33,
    },
    {
      transaction_id: "transaction_id_b198303cdf7249aa830d03c238e2f988",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_b6535869a2214ac28be5e8ee49877e7e",
      currency: "USD",
      units: 0,
      price_per_unit: 100.7,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 151,
      created_at: "2024-07-17T12:46:03.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T12:46:03.000Z",
      is_deleted: false,
      asset_name: "Endowus",
      asset_trading_price: 108.33,
    },
    {
      transaction_id: "transaction_id_58d2ec0314b444c6a3539c99196a108a",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      currency: "USD",
      units: 0,
      price_per_unit: 83,
      status: "COMPLETED",
      type: "SELL",
      total_amount: 9,
      created_at: "2024-07-17T10:44:47.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T10:44:47.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 87.45,
    },
    {
      transaction_id: "transaction_id_7595592f39ae468ab565d4647f3cd705",
      org_id: "org_b8226f56325342e796f971004e310003",
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      currency: "USD",
      units: 0,
      price_per_unit: 78.46,
      status: "COMPLETED",
      type: "BUY",
      total_amount: 322,
      created_at: "2024-07-17T10:43:50.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T10:43:50.000Z",
      is_deleted: false,
      asset_name: "Syfe",
      asset_trading_price: 87.45,
    },
  ],
  _metadata: {
    page: 1,
    page_count: 3,
    total_count: 30,
    current_page_count: 10,
    max_per_page: 10,
    links: {
      self: "/investment-transaction?page=1&limit=10",
      next: "/investment-transaction?page=2&limit=10",
      previous: null,
      first: "/investment-transaction?page=1&limit=10",
      last: "/investment-transaction?page=3&limit=10",
    },
  },
};

export const portfolioResponse = {
  request_id: "0fcfdc2b0305069547b4842f65995ef8",
  request_time: "2024-07-18T06:16:24.091Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: 29140.190000000002,
};

export const currentEarningValueResponse = {
  request_id: "79e35a6d083ecfafd6a7cad247fa6322",
  request_time: "2024-07-18T06:16:24.672Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: 4246.1900000000005,
};

export const currentInvestmentResponse = {
  request_id: "cc4e4fdbeb729f47962dbae6794bedee",
  request_time: "2024-07-18T06:16:25.144Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: 24894,
};

export const fetchAvailableBalanceResponse = {
  request_id: "a760c6ec7e09cbbc45e320ba91fa6f8e",
  request_time: "2024-07-18T06:16:25.587Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: 75389.1,
};

export const fetchALlAssetsResponse = {
  request_id: "c526b7fe5a869a5a425d65f35cf9f4e2",
  request_time: "2024-07-18T07:01:07.934Z",
  success: true,
  status_code: 200,
  status_text: "OK",
  data: [
    {
      asset_id: "asset_id_65419c8913174f50b626d294da8abe7e",
      asset_name: "Endowus",
      asset_min_size: 100,
      asset_yields: 5.3,
      asset_trading_price: 87.45,
      asset_provider_image:
        "https://cdn.prod.website-files.com/61263092cbd1b46804b2dcb7/61263092cbd1b41ad6b2dce6_endowus_dark.svg",
      asset_provider: "USD",
      currency: "USD",
      created_at: "2024-06-17T11:11:22.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T20:16:56.000Z",
      is_deleted: false,
    },
    {
      asset_id: "asset_id_e2d4ee817305420baa6a15be11fc34da",
      asset_name: "Syfe",
      asset_min_size: 100,
      asset_yields: 5.5,
      asset_trading_price: 90.05,
      asset_provider_image:
        "https://cdn.prod.website-files.com/64d3542964db4e6ae6de7d1d/65323c82f2b3eec77b4d5ec8_syfe-logo-dark.webp",
      asset_provider: "USD",
      currency: "USD",
      created_at: "2024-06-17T07:38:12.000Z",
      deleted_at: null,
      updated_at: "2024-07-17T13:30:25.000Z",
      is_deleted: false,
    },
  ],
  _metadata: {
    page: 1,
    page_count: 1,
    total_count: 7,
    current_page_count: 7,
    max_per_page: 10,
    links: {
      self: "/investment-assets?page=1&limit=10",
      next: null,
      previous: null,
      first: "/investment-assets?page=1&limit=10",
      last: "/investment-assets?page=1&limit=10",
    },
  },
};
